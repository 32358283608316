import React from 'react';
import Menu from './Menu';
import styles from './Navbar.module.scss';
import { useSiteMetadata } from '../../hooks';

type Props = {
    isIndex?: boolean,
};

const Navbar = ({ isIndex }: Props) => {
  const { menu } = useSiteMetadata();

  return (
        <div className={ styles['Navbar'] } >
            <div className={ styles['Navbar__inner']}>
                <Menu menu={menu} />
            </div>
        </div>
  );
};

export default Navbar;